<template>
  <LoginPage/>
</template>

<script>
import LoginPage from "@/components/Authentication/LoginPage/LoginPage";

export default {
  name: "Login",
  components: {LoginPage},
};
</script>

<style scoped>

</style>